body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-button {
  background-image: linear-gradient(101deg, #43c6ac 23%, #f4fd91 78%);
  width: 0px;
  height: 0;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #fff;
}
body::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(101deg, #43c6ac 23%, #f4fd91 78%);
  height: 0px;
}
body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  background:#ffffffcf;
  color: #232323;
  margin: 0;
  line-height: 1.48;
}
h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
  font-weight: 700;
}
h2{
  font-size: 42px;
  line-height: 1.25;
}
h3{
  font-size: 30px;
  line-height: 1.4;
}
h4 {
  font-size: 25px;
}
h6 {
  font-size: 18px;
}
a,a:focus,a:hover {
  text-decoration: none;
  color: #232323;
}
p,ul {
  padding: 0;
  margin: 0;
}
img {
  width: 100%;
}
.container{
  width: 1170px;
  margin: 0 auto;
}
.checked {
  color: #fad049;
}
.main-btn {
  background: #169466;
  border-radius: 2px;
  display: block;
  padding: 13px 40px;
  color: #f9f9f9;
  font-weight: 500;
  display: inline-block;
  text-transform: uppercase;
}
.main-btn:hover {
  background: #27BD87;
  color: #fff;
}

header {
  padding: 17px 0 0;

}
.header-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 160px;
}
.navigation {
  width: calc(100% - 160px);
}
.navigation ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.navigation ul li {
  list-style: none;
  margin-left: 5%;
}
.navigation ul li a {
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.navigation ul li a span {
  display: inline-block;
  background: #53CD9F;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  box-sizing: border-box;
  margin-right: 10px;
}
.banner-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

section.banner-section {
  text-align: center;
  padding: 4% 0 0;
}
section.banner-section h2 {
  margin-top: 10px;
}
section.banner-section h2>span {
  display: block;
}
section.banner-section p {
  margin-top: 15px;
}
section.banner-section .main-btn {
  margin-top: 24px;
}
section.banner-section img {
  width: 85%;
  display: block;
  margin: 0 auto;
}
.banner-img-grid {
  max-width: 70%;
  margin: 30px auto 0;
  position: relative;
}
section.banner-section img.banner-icon1 {
  position: absolute;
  width: 26%;
  top: 5%;
  left: -5%;
  animation: animation1 8s ease-in-out infinite;
}
section.banner-section img.banner-icon2 {
  position: absolute;
  width: 30%;
  top: -6%;
  right: -5%;
  animation: animation2 8s ease-in-out infinite;
}
section.banner-section img.banner-icon3 {
  position: absolute;
  width: 26%;
  bottom: 18%;
  left: -8%;
  animation: animation2 8s ease-in-out infinite;
}
section.banner-section img.banner-icon4 {
  position: absolute;
  width: 26%;
  bottom: 17%;
  right: -8%;
  animation: animation1 8s ease-in-out infinite;
}
@keyframes animation1 {
  0% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(-20px);
  }
}
@keyframes animation2 {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
section.trusted-section{
  padding: 4% 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}
section.trusted-section  .slick-track{
  display: flex;
  align-items: center;
}
section.trusted-section .trustedImg {
  outline: none;
}
section.trusted-section .trustedImg img {
  width: 60%;
  margin: 0 auto;
  display: block;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}


section.join-section {
  text-align: center;
  padding: 5% 0 0;
}
section.join-section h3>span {
  display: block;
}
section.join-section p {
  padding: 0 19%;
  box-sizing: border-box;
  text-align: left;
  margin-top: 20px;
}
section.join-section p>span {
  display: block;
  margin-top: 24px;
}
section.join-section p b {
  font-weight: 600;
}
section.join-section a {
  margin-top: 40px;
}


section.projectGridSection {
  padding: 5% 0 0;
}
section.projectGridSection .projectGridInner {
  width: 94%;
  margin: 0 auto;
}
section.projectGridSection .projectGridInner .slick-slider .slick-list {
  padding: 5% 0;
}
section.projectGridSection .projectGridInner .projectGrid {
  width: 20%;
  outline: none;
}
section.projectGridSection .projectGridInner .projectGrid img{
  box-shadow:0 13px 16px 0 rgba(0, 0, 0, 0.07);
  width: 90%;
  margin: 0 auto;
  display: block;
  border-radius:20px;
}
section.projectGridSection .projectGridButton{
  width: 100%;
  text-align: center;
  margin: 5% 0;
}
ul.slick-dots {
  bottom: 0;
}
section.projectGridSection .slick-prev, 
section.projectGridSection .slick-next {
  display: none !important;
}



section.bankrupt-section {
  padding: 5% 0 0;
}
.bankrupt-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.bankrupt-content {
  width: 40%;
}
.bankrupt-content p,
.bankrupt-content a {
  margin-top: 20px;
}
.bankrupt-content p {
  padding-right: 10%;
}
.bankrupt-content p>span {
  display: block;
  margin-top: 20px;
}
.bankrupt-img {
  width: 50%;
}

section.project-section {
  overflow: hidden;
  text-align: center;
  position: relative;
  z-index: 2;
  margin-top: 5%;
}
/* section.project-section:after {
  content: "";
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(#F8F8F8, rgba(255, 255, 255, 0));
  z-index: -1;
} */
.project-inner {
  outline: none;
}
.project-inner .mobile-img {
  display: none;
}
.project-inner-main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(to right, rgba(67, 198, 172, 0.12)  , rgba(113, 207, 40, 0.12));
  padding: 50px;
  box-sizing: border-box;
}
section.project-section .slick-slide>div, section.project-section .slick-slide .project-inner, section.project-section .slick-slide .project-inner-main {
  height: 100%;
}
section.project-section .slick-slide {
  padding: 0 20px;
  box-sizing: border-box;
  height: inherit !important;
}
section.project-section .slick-track{
  display: flex !important;
}
.project-inner-img {
  width: 48%;
}
.project-content {
  width: 48%;
}
.project-left {
  width: 100%;
}
.project-left h3 {
  color: #000000;
}
.project-left p {
  max-width: 96%;
  margin: 15px 0 0;
}
.project-left p>b {
  display: block;
}
.project-right {
  width: 100%;
  position: relative;
}
.project-right ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 33px;
}
.project-right ul li {
  width: 29%;
  margin: 0;
}
.project-right ul li>span {
  font-weight: 600;
  color: #0D7A5C;
  font-size: 25px;
  display: block;
  width: 100%;
}
.project-right ul li>b {
  font-weight: 600;
  color: #000;
  width:100%;
  margin-top: 2px;
  display: block;
}
.project-right ul li>b i {
  font-style: normal;
  display: block;
}
/*
.project-inner p b {
  display: block;
}
.project-inner ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 60%;
  margin: 20px auto 0;
}
.project-inner ul li {
  list-style: none;
}
.project-inner ul li span {
  color: #27BD87;
  display: block;
  font-size: 60px;
}
.project-inner ul li i {
  display: block;
  font-style: normal;
} */


section.process-section {
  text-align: center;
  margin-top: 50px;
}
.process-inner {
  background: #FFFFFF;
  box-shadow: 0px 16px 88px rgba(26, 34, 43, 0.08);
  border-radius: 10px;
  max-width: 80%;
  margin: 0 auto;
  position: relative;
}
.process-inner:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -23px;
  height: 60px;
  background: #ffffff;
  border-radius: 50%;
}
img.sprint-left {
  position: absolute;
  left: -9%;
  width: 15%;
  bottom: 5%;
}
img.sprint-right {
  position: absolute;
  right: -6%;
  width: 10%;
  bottom: 5%;
}
.process-inner p {
  color: #000;
  position: relative;
  z-index: 2;
}
.process-inner>h2 {
  padding: 0 0 25px;
  position: relative;
  z-index: 2;
}
.process-inner>h2 span {
  display: block;
  font-size: 16px;
  font-weight: 400;
}
.process-inner h2 img {
  position: absolute;
  top: -11%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
}
.achieved {
  background: #F2F2F2;
  padding: 40px;
}
.achieved h4 {
  padding: 0 8%;
}
.achieved ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}
.achieved ul li {
  width: calc(92% / 5);
  list-style: none;
  margin-top: 8px;
}
.sprint {
  max-width: 60%;
  margin: 0 auto;
  padding: 50px 0;
  position: relative;
  z-index: 2;
}
.sprint h3 {
  color: #27BD87;
}
.sprint p, .sprint p>span {
  margin-top: 16px;
  display: block;
}
.sprint .main-btn {
  font-weight: 700;
  margin-top: 25px;
}
.sprint .main-btn span {
  font-weight: 400;
  font-size: 10px;
  display: block;
  margin-top: 5px;
}
section.team-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.team-img img.mobile-img {
  display: none;
}
.team-img:nth-child(1) {
  width: 24%;
}
.team-img:nth-child(2) {
  width: 25%;
}
.team-img:nth-child(3) {
  width: 25%;
  position: relative;
  top: 4px;
}
.team-img:nth-child(4) {
  width: 23%;
}
section.customer-section {
  padding: 6% 0;
  text-align: center;
  position: relative;
  background: rgba(207, 207, 207, 0.15);
  margin-top: 90px;
}
/* section.customer-section:after {
  content: "";
  position: absolute;
  top: -20%;
  left: 0;
  right: 0;
  height: 96%;
  z-index: -1;
  background: #CFCFCF;
  opacity: 0.15;
} */
section.customer-section h3 {
  margin-bottom: 1.5%;
}
section.customer-section .container>p {
  font-size: 16px;
}
.customer-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2%;
}
.customer-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(26, 34, 43, 0.08);
  border-radius: 10px;
  width: calc((100% - 60px) / 3);
  text-align: left;
  padding: 2%;
  box-sizing: border-box;
  margin-top: 30px;
}
.customer-profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.customer-img {
  width: 50px;
}
.customer-content {
  width: calc(100% - 65px);
  position: relative;
}
.customer-content h6 {
  font-weight: 600;
}
.customer-card .customer-content p {
  margin-top: 0;
}
.customer-content a {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
}
.customer-card ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 4%;
}
.customer-card ul li {
  margin-right: 3px;
  color: #F3B515;
  font-size: 16px;
}
.customer-card p,
.customer-card span {
  margin-top: 3%;
  display: block;
}
.customer-card span {
  color: #454545;
  font-size: 16px;
}

section.advance-section {
  padding: 6% 0;
  text-align: center;
}
section.advance-section h6 {
  color: #27BD87;
}
section.advance-section h3 {
  margin-top: 1%;
}
section.advance-section h3>span {
  display: block;
}
section.advance-section .container>p {
  margin-top: 1%;
  font-size: 16px;
}
.advance-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  margin-top: 10px;
}
.advance-card {
  background: #FFFFFF;
  border: 1px solid rgba(26, 34, 43, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 16px 32px rgba(26, 34, 43, 0.08);
  border-radius: 5px;
  width: calc((100% - 90px) / 4);
  margin-top: 30px;
}
.advance-img {
  line-height: 0;
  background: #0D7A5C;
  border-radius: 5px;
  padding: 9% 0 0;
}
.advance-img img {
  width: auto;
  margin: 0 auto;
  display: block;
  height: 150px;
}
.advance-content {
  padding: 20px;
}
section.advance-section .advance-content h6 {
  color: #232323;
  font-weight: 600;
}
section.advance-section .advance-content p {
  margin-top: 7px;
}

section.benefit-section {
  padding: 6% 0;
  background: rgba(207, 207, 207, 0.1);
  text-align: center;
}
section.benefit-section h6 {
  color: #27BD87;
}
.benefit-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.benefit-card {
  width: 31%;
  text-align: left;
  margin-top: 5%;
}
.benefit-card img {
  width: auto;
  height: 40px;
}
section.benefit-section .benefit-card h6 {
  color: #232323;
  margin-top: 15px;
}
.benefit-card p {
  margin-top: 15px;
}
.benefit-card h6>span {
  display: block;
}

section.get-section {
  padding: 6% 0;
}
.tool-inner {
  text-align: center;
  padding-top: 5%;
}
.tool-inner h6 {
  color: #27BD87;
}
.tool-inner ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin-top: 3%;
}
.tool-inner ul li {
  width: calc(100% / 6);
  margin-top: 2%;
}
.tool-inner ul li img {
  width: 80%;
  margin: 0 auto;
}


section.high-section {
  position: relative;
  color: #fff;
  padding: 6% 0;
  text-align: center;
  z-index: 2;
}
.high-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
section.high-section h3>span {
  display: block;
}
section.high-section p {
  font-size: 16px;
  margin-top: 1%;
}
section.high-section p>span {
  display: block;
}
section.high-section .main-btn {
  margin-top: 3%;
  background: #fff;
  color: #000;
}
section.talk-section {
  padding: 6% 0;
  text-align: center;
}
section.talk-section h6 {
  font-weight: 600;
  color: #27BD87;
}
section.talk-section p {
  padding: 0 16%;
  margin-top: 10px;
}
section.talk-section .calendly-inline-widget {
  height: 650px !important;
}
.expect-grid {
  margin-top: 40px;
}
.expect-grid ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 66%;
  margin: 10px auto 0;
}
.expect-grid ul li {
  width: 41%;
  text-align: left;
  margin: 16px 0;
  list-style: none;
  position: relative;
  padding-left: 26px;
}
.expect-grid ul li:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 1px;
  width: 7px;
  height: 14px;
  border: solid #27BD87;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

footer {
  background: #232323;
}
.footer-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 5px;
}
.copy-right {
  color: #D9DBE1;
  width: 25%;
}
.copy-right a {
  color: #D9DBE1;
}
.footer-logo {
  width: 140px;
}
.footer-social {
  width: 25%;
}
.footer-social ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
}
.footer-social ul li {
  margin-left: 5%;
}
.footer-social ul li a {
  display: block;
  background: rgba(249, 249, 249, 0.1);
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
}
ul.slick-dots {
  position: static;
  margin-top: 20px;
}
.slick-dots li {
  margin: 0 1px;
}
.slick-dots li button:before {
  opacity: 1;
  color: rgba(10, 152, 113, 0.3);
  font-size: 13px;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #0A9871;
}


.footer-fixed {
  display: none;
}
section.bankrupt-section.design-section .bankrupt-content p {
  margin-top: 5px;
}
section.bankrupt-section.design-section .bankrupt-content h5 {
  font-weight: 700;
  color: #169466;
  margin-top: 7px;
  font-size: 22px;
}
section.bankrupt-section.design-section .bankrupt-content b {
  display: block;
  margin-top: 20px;
}
section.bankrupt-section.design-section .bankrupt-content ul li {
  position: relative;
  list-style: none;
  padding: 0 24px;
  margin-top: 13px;
}
section.bankrupt-section.design-section .bankrupt-content ul li:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 1px;
  width: 7px;
  height: 14px;
  border: solid #27BD87;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}