@media screen and (min-width: 1600px) {
.container {
    width: 1440px;
}

}

@media screen and (min-width:1900px) {

.container {
    width: 1600px;
}
body {
    font-size: 18px;
}
h2{
    font-size: 60px;
  }
h3{
    font-size: 40px;
}
h6 {
    font-size: 22px;
}
    
}


@media screen and (max-width:1199px) {

.container {
    width: 970px;
}
.project-right:after {
    right: 0;
}
body {
    font-size: 13px;
}
h2{
    font-size: 32px;
  }
h3{
    font-size: 25px;
}
h6 {
    font-size: 20px;
}
img.sprint-left {
    width: 12%;
}
}

@media screen and (max-width:991px) {

.container {
    width: 750px;
}
h2{
    font-size: 28px;
  }
h3{
    font-size: 22px;
}
h6 {
    font-size: 18px;
}
section.join-section p {
    padding: 0 10%;
}
section.join-section p>span {
    margin-top: 15px;
}
.bankrupt-content {
    width: 46%;
}
.sprint {
    max-width: 80%;
}
section.advance-section .container>p {
    font-size: 13px;
}
.advance-card {
    width: calc((100% - 40px) / 3);
}
.copy-right {
    width: 35%;
    font-size: 11px;
}
.footer-social {
    width: 35%;
}
section.customer-section .container>p, section.high-section p {
    font-size: 14px;
}
img.sprint-left {
    width: 12%;
    bottom: 11%;
}
}

@media screen and (max-width:767px) {

.container {
    width: 100%;
    padding: 0 6%;
    box-sizing: border-box;
}
body {
    font-size: 12px;
}
h2{
    font-size: 22px;
  }
h3{
    font-size: 20px;
}
h4{
    font-size: 18px;
}
h6 {
    font-size: 14px;
}
.banner-img {
    height: 210px;
    object-fit: cover;
}
header {
    padding: 8px 0;
}
header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: #fff;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    animation: moon 400ms linear;
}
@keyframes moon {
  from{ 
    -webkit-transform: translateY(-61.5px); opacity: 0; 
    transform: translateY(-61.5px); opacity: 0; 
   }
  to{ 
    -webkit-transform: translateY(0px); opacity: 1; 
    transform: translateY(0px); opacity: 1; 
   }
}

.logo {
    width: 100px;
}
.navigation {
    width: calc(100% - 100px);
}
.navigation ul li {
    margin-left: 0;
    font-size: 10px;
}
.navigation ul li a {
    padding: 13px 20px;
    font-size: 12px;
}
section.banner-section {
    padding: 70px 0 0;
    overflow: hidden;
}
section.banner-section .container {
    flex-wrap: wrap;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
}
section.banner-section img {
    width: 100%;
}
section.banner-section img.banner-icon1,
section.banner-section img.banner-icon2,
section.banner-section img.banner-icon3,
section.banner-section img.banner-icon4 {
    display: none;
}
.banner-img-grid {
    margin: 0px auto 0;
    order: -1;
    max-width: 100%;
}
section.banner-section h6 {
    margin-top: 15px;
}
section.banner-section p {
    max-width: 200px;
}
section.banner-section h2>span {
    display: inline;
}
section.trusted-section {
    padding: 50px 0;
    overflow: hidden;
}
section.trusted-section .container {
    padding: 0;
}
section.join-section h3>span {
    display: inline;
}
section.join-section p {
    padding: 0;
    text-align: center;
}
.bankrupt-content {
    width: 100%;
    margin-top: 20px;
}
.bankrupt-img {
    width: 100%;
    order: -1;
}
section.bankrupt-section {
    padding: 50px 0;
    text-align: center;
}
.project-inner .desktop-img {
    display: none;
}
.project-inner .mobile-img {
    display: block;
}
.project-content, .project-inner-img, .project-left, .project-right {
    width: 100%;
    text-align: center;
}
.project-left h3 {
    margin-top: 0px;
}
.project-right:after {
    display: none;
}
.project-right {
    margin-top: 25px;
}
.project-right ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.project-right ul li {
    width: 29%;
    margin: 0;
}
.project-right ul li>span {
    font-size: 18px;
    margin: 0 auto;
}
.project-right ul li>b {
    width:100%;
}
.process-inner {
    max-width: 100%;
}
.process-inner>h2 {
    padding: 0 0 12px;
}
section.team-section {
    display: block;
    white-space: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
section.team-section .team-img {
    width: 74%;
    display: inline-block;
    margin-left: 6%;
}
section.team-section .team-img:nth-child(3) {
    transform: translateY(-4px);
}
.team-img img.desktop-img {
    display: none;
}
.team-img img.mobile-img {
    display: block;
}



.achieved {
    padding: 15px;
}
.process-inner:after {
    top: -27px;
    height: 70px;
}
.sprint {
    max-width: 90%;
}
.sprint .main-btn {
    padding: 9px 17px;
}
.customer-card {
    width: 100%;
    padding: 24px;
}
section.advance-section .container {
    padding: 0;
}
section.advance-section .container>h6,
section.advance-section .container>h3, 
section.advance-section .container>p{
    padding: 0 6%;
}
.advance-inner {
    margin-top: 30px;
    display: block;
    white-space: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
.advance-card {
    width: 290px;
    white-space: normal;
    display: inline-block;
    margin: 0 10px;
    box-shadow: 0px 6px 12px rgba(26, 34, 43, 0.08);
}
section.customer-section {
    padding: 50px 0;
    margin-top: 60px;
}
section.customer-section .container>p, section.high-section p {
    font-size: 12px;
}
section.advance-section {
    padding: 50px 0;
}
section.advance-section h3>span {
    display: inline;
}
section.high-section,
section.benefit-section,
section.talk-section {
    padding: 50px 0;
}
.benefit-card {
    width: 100%;
    text-align: center;
    margin-top: 40px;
}
.tool-inner {
    padding-top: 0px;
}
.tool-inner ul li {
    width: calc(100% / 3);
}
section.high-section h3>span {
    display: inline;
}
section.talk-section p {
    padding: 0;
    margin: 20px 0;
}
.footer-inner {
    padding: 20px 0 20px;
    justify-content: center;
}
.copy-right {
    width: 100%;
    font-size: 11px;
    text-align: center;
    order: 3;
}
.footer-social {
    width: 100%;
    order: 2;
    margin: 15px 0;
}
.footer-social ul {
    justify-content: center;
    list-style: none;
}
section.projectGridSection .projectGridInner {
    width: 100%;
}
img.sprint-left,
img.sprint-right {
    display: none;
}
.footer-fixed {
    display: block;
    background: #242323;
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 9px 0% 5px;
}
.footer-fixed ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.footer-fixed ul li {
    list-style: none;
    text-align: center;
    font-size: 14px;
    color: #fff;
    width: 49%;
}
.footer-fixed ul li:first-child {
    border-right: 1px solid #949494;
}
.footer-fixed ul li a {
    display: block;
    color: #fff;
    font-size: 12px;
}
.footer-fixed ul li img {
    width: auto;
    height: 20px;
    display: block;
    margin: 0 auto 5px;
}

section.project-section .slick-slide {
    padding: 0 3px;
}
.project-inner-main {
    padding: 30px 20px;
}
.expect-grid ul {
    width: 100%;
    margin: 5px auto 0;
}
section.bankrupt-section.design-section {
    padding: 0;
}
section.bankrupt-section.design-section .bankrupt-content {
    text-align: left;
    margin-top: 20px;
}
.bankrupt-content p {
    padding-right: 0;
}

}


@media screen and (max-width: 767px) {
.navigation ul li a {
    padding: 11px 14px;
    font-size: 11px;
}




}